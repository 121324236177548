import React from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';

import css from './SearchBar.module.css';
import SearchFiltersIcon from './SearchFiltersIcon';

const SearchBarComponent = props => {
  const { intl } = props;
  return (
    <div className={css.root}>
      <input
        className={css.input}
        type="text"
        placeholder={intl.formatMessage({
          id: 'ManageListingsPage.searchBarplaceholder',
        })}
        autoComplete="off"
      />

      <div className={css.icon}>
        <SearchFiltersIcon />
      </div>
    </div>
  );
};

const SearchBar = compose(injectIntl)(SearchBarComponent);
SearchBar.displayName = 'SearchBar';

export default SearchBar;
