import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const SignoutIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8153 22.6811H14.6885C10.3565 22.6811 8.26852 20.9736 7.90752 17.149C7.8685 16.749 8.1612 16.388 8.57099 16.3489C8.96126 16.3099 9.33201 16.6124 9.37104 17.0124C9.65399 20.076 11.098 21.2176 14.6982 21.2176H14.8251C18.7961 21.2176 20.2011 19.8126 20.2011 15.8416V9.48013C20.2011 5.50912 18.7961 4.10414 14.8251 4.10414H14.6982C11.0785 4.10414 9.63447 5.2652 9.37104 8.38737C9.32226 8.7874 8.98077 9.08986 8.57099 9.05083C8.1612 9.02156 7.8685 8.66056 7.89777 8.26054C8.2295 4.37733 10.3272 2.64062 14.6885 2.64062H14.8153C19.6059 2.64062 21.6548 4.68955 21.6548 9.48013V15.8416C21.6548 20.6322 19.6059 22.6811 14.8153 22.6811Z"
        fill="#013957"
      />
      <path
        d="M14.8153 22.6811H14.6885C10.3565 22.6811 8.26852 20.9736 7.90752 17.149C7.8685 16.749 8.1612 16.388 8.57099 16.3489C8.96126 16.3099 9.33201 16.6124 9.37104 17.0124C9.65399 20.076 11.098 21.2176 14.6982 21.2176H14.8251C18.7961 21.2176 20.2011 19.8126 20.2011 15.8416V9.48013C20.2011 5.50912 18.7961 4.10414 14.8251 4.10414H14.6982C11.0785 4.10414 9.63447 5.2652 9.37104 8.38737C9.32226 8.7874 8.98077 9.08986 8.57099 9.05083C8.1612 9.02156 7.8685 8.66056 7.89777 8.26054C8.2295 4.37733 10.3272 2.64062 14.6885 2.64062H14.8153C19.6059 2.64062 21.6548 4.68955 21.6548 9.48013V15.8416C21.6548 20.6322 19.6059 22.6811 14.8153 22.6811Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M14.5798 13.3932H3.47663C3.0766 13.3932 2.74487 13.0615 2.74487 12.6614C2.74487 12.2614 3.0766 11.9297 3.47663 11.9297H14.5798C14.9798 11.9297 15.3116 12.2614 15.3116 12.6614C15.3116 13.0615 14.9798 13.3932 14.5798 13.3932Z"
        fill="#013957"
      />
      <path
        d="M14.5798 13.3932H3.47663C3.0766 13.3932 2.74487 13.0615 2.74487 12.6614C2.74487 12.2614 3.0766 11.9297 3.47663 11.9297H14.5798C14.9798 11.9297 15.3116 12.2614 15.3116 12.6614C15.3116 13.0615 14.9798 13.3932 14.5798 13.3932Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M5.65263 16.6622C5.46725 16.6622 5.28187 16.5939 5.13552 16.4476L1.867 13.179C1.58405 12.8961 1.58405 12.4278 1.867 12.1448L5.13552 8.87627C5.41847 8.59333 5.88679 8.59333 6.16974 8.87627C6.45269 9.15922 6.45269 9.62755 6.16974 9.91049L3.41832 12.6619L6.16974 15.4133C6.45269 15.6963 6.45269 16.1646 6.16974 16.4476C6.03314 16.5939 5.83801 16.6622 5.65263 16.6622Z"
        fill="#013957"
      />
      <path
        d="M5.65263 16.6622C5.46725 16.6622 5.28187 16.5939 5.13552 16.4476L1.867 13.179C1.58405 12.8961 1.58405 12.4278 1.867 12.1448L5.13552 8.87627C5.41847 8.59333 5.88679 8.59333 6.16974 8.87627C6.45269 9.15922 6.45269 9.62755 6.16974 9.91049L3.41832 12.6619L6.16974 15.4133C6.45269 15.6963 6.45269 16.1646 6.16974 16.4476C6.03314 16.5939 5.83801 16.6622 5.65263 16.6622Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export default SignoutIcon;
