import React from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import css from "./AsideContainerColumn.module.css"

const AsideContainerColumnComponent = (props) => {
    const { children } = props;
    return (
        <aside className={css.root}>
            {children}
        </aside>
    );
}

const AsideContainerColumn = compose(injectIntl)(AsideContainerColumnComponent);
AsideContainerColumn.displayName = 'AsideContainerColumn';

export default AsideContainerColumn;