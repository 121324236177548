import React from 'react';

const StarFullIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ fill: '#013957' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3L15.09 8.63387L22 9.54286L17 13.9258L18.18 20.1176L12 17.1927L5.82 20.1176L7 13.9258L2 9.54286L8.91 8.63387L12 3Z"
        stroke="#013957"
        strokeWidth="1.40397"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3L15.09 8.63387L22 9.54286L17 13.9258L18.18 20.1176L12 17.1927L5.82 20.1176L7 13.9258L2 9.54286L8.91 8.63387L12 3Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.40397"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarFullIcon;
