import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
const { UUID } = sdkTypes;

// // ================ Action types ================ //

export const UPLOAD_FILE_STATUS = 'app/ProfileSettingsPage/UPLOAD_FILE_STATUS';

// export const CLEAR_UPDATED_FORM = 'app/ProfileSettingsPage/CLEAR_UPDATED_FORM';

// export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
// export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
// export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';

// export const UPDATE_PROFILE_REQUEST = 'app/ProfileSettingsPage/UPDATE_PROFILE_REQUEST';
// export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSettingsPage/UPDATE_PROFILE_SUCCESS';
// export const UPDATE_PROFILE_ERROR = 'app/ProfileSettingsPage/UPDATE_PROFILE_ERROR';

// // ================ Reducer ================ //

const initialState = {
  uploadInProgress: false,
  //   image: null,
  //   uploadImageError: null,
  //   updateInProgress: false,
  //   updateProfileError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case UPLOAD_FILE_STATUS: {
      return { ...state, uploadInProgress: payload.params };
    }
    //     case UPLOAD_IMAGE_REQUEST:
    //       // payload.params: { id: 'tempId', file }
    //       return {
    //         ...state,
    //         image: { ...payload.params },
    //         uploadInProgress: true,
    //         uploadImageError: null,
    //       };
    //     case UPLOAD_IMAGE_SUCCESS: {
    //       // payload: { id: 'tempId', uploadedImage }
    //       const { id, uploadedImage } = payload;
    //       const { file } = state.image || {};
    //       const image = { id, imageId: uploadedImage.id, file, uploadedImage };
    //       return { ...state, image, uploadInProgress: false };
    //     }
    //     case UPLOAD_IMAGE_ERROR: {
    //       // eslint-disable-next-line no-console
    //       return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    //     }

    //     case UPDATE_PROFILE_REQUEST:
    //       return {
    //         ...state,
    //         updateInProgress: true,
    //         updateProfileError: null,
    //       };
    //     case UPDATE_PROFILE_SUCCESS:
    //       return {
    //         ...state,
    //         image: null,
    //         updateInProgress: false,
    //       };
    //     case UPDATE_PROFILE_ERROR:
    //       return {
    //         ...state,
    //         image: null,
    //         updateInProgress: false,
    //         updateProfileError: payload,
    //       };

    //     case CLEAR_UPDATED_FORM:
    //       return { ...state, updateProfileError: null, uploadImageError: null };

    default:
      return state;
  }
}

// // ================ Selectors ================ //

// // ================ Action creators ================ //

export const uploadFileStatus = params => ({ type: UPLOAD_FILE_STATUS, payload: { params } });

// export const clearUpdatedForm = () => ({
//   type: CLEAR_UPDATED_FORM,
// });

// // SDK method: images.upload
// export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
// export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
// export const uploadImageError = error => ({
//   type: UPLOAD_IMAGE_ERROR,
//   payload: error,
//   error: true,
// });

// // SDK method: sdk.currentUser.updateProfile
// export const updateProfileRequest = params => ({
//   type: UPDATE_PROFILE_REQUEST,
//   payload: { params },
// });
// export const updateProfileSuccess = result => ({
//   type: UPDATE_PROFILE_SUCCESS,
//   payload: result.data,
// });
// export const updateProfileError = error => ({
//   type: UPDATE_PROFILE_ERROR,
//   payload: error,
//   error: true,
// });

// // ================ Thunk ================ //

export function updateUploadFileStatus(isUploading) {
  return (dispatch, getState, sdk) => {
    return dispatch(uploadFileStatus(isUploading));
  }
}

// // Images return imageId which we need to map with previously generated temporary id
// export function uploadImage(actionPayload) {
//   return (dispatch, getState, sdk) => {
//     const id = actionPayload.id;
//     dispatch(uploadImageRequest(actionPayload));

//     const bodyParams = {
//       image: actionPayload.file,
//     };
//     const queryParams = {
//       expand: true,
//       'fields.image': ['variants.square-small', 'variants.square-small2x'],
//     };

//     return sdk.images
//       .upload(bodyParams, queryParams)
//       .then(resp => {
//         const uploadedImage = resp.data.data;
//         dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
//       })
//       .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
//   };
// }

// export const updateProfile = actionPayload => {
//   return (dispatch, getState, sdk) => {
//     dispatch(updateProfileRequest());

//     const queryParams = {
//       expand: true,
//       include: ['profileImage'],
//       'fields.image': ['variants.square-small', 'variants.square-small2x'],
//     };

//     return sdk.currentUser
//       .updateProfile(actionPayload, queryParams)
//       .then(response => {
//         dispatch(updateProfileSuccess(response));

//         const entities = denormalisedResponseEntities(response);
//         if (entities.length !== 1) {
//           throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
//         }
//         const currentUser = entities[0];

//         // Update current user in state.user.currentUser through user.duck.js
//         dispatch(currentUserShowSuccess(currentUser));
//       })
//       .catch(e => dispatch(updateProfileError(storableError(e))));
//   };
// };

export const loadData = (params, config) => (dispatch, getState, sdk) => {

  const { id, type } = params;

  const payload = { id: new UUID(id) };
  return Promise.all([dispatch(requestShowListing(payload, config))])
    .then(response => {

      // const listing = response[0]?.data?.data;
      // const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias;
      // if (listing && isBookingProcessAlias(transactionProcessAlias)) {
      //   fetchLoadDataExceptions(dispatch, listing, search, config.localization.firstDayOfWeek);
      // }

      return response;
    })
    .catch(e => {
      throw e;
    });
};

export function requestShowListing(actionPayload, config) {
  return (dispatch, getState, sdk) => {
    // const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      include: ['author', 'images', 'currentStock'],
      // 'fields.image': imageVariantInfo.fieldsImage,
      // ...imageVariantInfo.imageVariants,
    };

    // dispatch(showListingsRequest(actionPayload));
    return sdk.listings
      .show({ ...actionPayload, ...queryParams })
      .then(response => {
        // EditListingPage fetches new listing data, which also needs to be added to global data
        dispatch(addMarketplaceEntities(response));
        // In case of success, we'll clear state.EditListingPage (user will be redirected away)
        // dispatch(showListingsSuccess(response));
        return response;
      })
      .catch(e => {

      });
  };
}
