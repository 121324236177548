import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const BusinessIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8087 22.0002H8.19128C3.79218 22.0002 2.9733 20.0002 2.76382 18.056L2.04969 10.6049C1.94495 9.62814 1.91638 8.18628 2.90665 7.10721C3.76362 6.17698 5.18237 5.73047 7.23909 5.73047H16.7609C18.8272 5.73047 20.2459 6.18628 21.0933 7.10721C22.0836 8.18628 22.0551 9.62814 21.9503 10.6142L21.2362 18.0467C21.0267 20.0002 20.2078 22.0002 15.8087 22.0002ZM7.23909 7.11652C5.6299 7.11652 4.52537 7.42349 3.96358 8.03745C3.49701 8.53977 3.34466 9.31186 3.46844 10.4653L4.18258 17.9165C4.34445 19.3863 4.75389 20.6049 8.19128 20.6049H15.8087C19.2366 20.6049 19.6556 19.3863 19.8174 17.9072L20.5316 10.4746C20.6553 9.31186 20.503 8.53977 20.0364 8.03745C19.4746 7.42349 18.3701 7.11652 16.7609 7.11652H7.23909Z"
        fill="#012E46"
      />
      <path
        d="M15.8087 7.11628C15.4183 7.11628 15.0945 6.8 15.0945 6.4186V5.67442C15.0945 4.0186 15.0945 3.39535 12.7617 3.39535H11.2382C8.90533 3.39535 8.90533 4.0186 8.90533 5.67442V6.4186C8.90533 6.8 8.58159 7.11628 8.19119 7.11628C7.80079 7.11628 7.47705 6.8 7.47705 6.4186V5.67442C7.47705 4.03721 7.47705 2 11.2382 2H12.7617C16.5228 2 16.5228 4.03721 16.5228 5.67442V6.4186C16.5228 6.8 16.1991 7.11628 15.8087 7.11628Z"
        fill="#012E46"
      />
    </svg>
  );
};

export default BusinessIcon;
