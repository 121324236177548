import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { twitterPageURL } from '../../util/urlHelpers';
import {
    IconSocialMediaFacebook,
    IconSocialMediaInstagram,
    IconSocialMediaTwitter,
    Logo,
    ExternalLink,
    NamedLink,
} from '../../components';
import image from './images/image.png';

import css from './Footer.module.css';

const renderSocialMediaLinks = (intl, config) => {
    const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
    const siteTwitterPage = twitterPageURL(siteTwitterHandle);

    const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
    const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
    const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

    const fbLink = siteFacebookPage ? (
        <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
            <IconSocialMediaFacebook />
        </ExternalLink>
    ) : null;

    const twitterLink = siteTwitterPage ? (
        <ExternalLink
            key="linkToTwitter"
            href={siteTwitterPage}
            className={css.icon}
            title={goToTwitter}
        >
            <IconSocialMediaTwitter />
        </ExternalLink>
    ) : null;

    const instragramLink = siteInstagramPage ? (
        <ExternalLink
            key="linkToInstagram"
            href={siteInstagramPage}
            className={css.icon}
            title={goToInsta}
        >
            <IconSocialMediaInstagram />
        </ExternalLink>
    ) : null;
    // return [fbLink, twitterLink, instragramLink].filter(v => v != null);
    return [fbLink, twitterLink, instragramLink];


};

const Footer = props => {
    const config = useConfiguration();
    const { rootClassName, className, intl, isAuthenticated, currentPage, footerPartHide } = props;
    const socialMediaLinks = renderSocialMediaLinks(intl, config);
    const classes = classNames(rootClassName || css.root, className);

    //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////
    // THIS IS A TEMPORARY FOOTER -- THE ORIGINAL ONE IS Footer-main.js //
    //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////

    return (
        <div className={classes}>
            <div className={css.content}>
                <div className={css.dynamicFooter} style={{ paddingBottom: 40 }}>
                    <div className={css.imageContainer}>
                        <img className={css.image} src={image} />
                    </div>
                    <div>
                        <p className={css.footerHeaders}>How it works</p>
                        <ul>
                            <li>
                                <a href="">How it works</a>
                            </li>
                            <li>
                                <NamedLink name="PricingPage">
                                    <FormattedMessage id="Footer.toPricingPage" />
                                </NamedLink>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className={css.footerHeaders}>About</p>
                        <ul>
                            <li>
                                <NamedLink name="AboutUsPage">
                                    <FormattedMessage id="Footer.toAboutPage" />
                                </NamedLink>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className={css.footerHeaders}>Help & Support</p>
                        <ul>
                            <li>
                                <a href="">Help</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div >
    );
};

Footer.defaultProps = {
    rootClassName: null,
    className: null,
};

Footer.propTypes = {
    rootClassName: string,
    className: string,
    intl: intlShape.isRequired,
};

export default injectIntl(Footer);
