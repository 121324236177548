import React from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';

import css from './LineDivider.module.css';

const LineDividerComponent = props => {
  const { white, customStyle } = props;
  return <div className={white ? css.rootWhite : css.root} style={{ ...customStyle }} />;
};

const LineDivider = compose(injectIntl)(LineDividerComponent);
LineDivider.displayName = 'LineDivider';

export default LineDivider;
