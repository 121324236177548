export const selectors = {
    all: "all",
    yourGigs: "yourGigs",
    purchasedGigs: "purchasedGigs",
    favorites: "favorites",
    recentlyViewed: "recentlyViewed",
    bestMatched: "bestMatched",
    topRated: "topRated",
    availableNow: "availableNow",
    personalInformation: "personalInformation",
    notifications: "notifications",
}