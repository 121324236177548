import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const SettingsIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      style={{ fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9951 15.9345C13.6512 15.9345 14.9936 14.5921 14.9936 12.936C14.9936 11.2799 13.6512 9.9375 11.9951 9.9375C10.339 9.9375 8.99658 11.2799 8.99658 12.936C8.99658 14.5921 10.339 15.9345 11.9951 15.9345Z"
        stroke="#013957"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9951 15.9345C13.6512 15.9345 14.9936 14.5921 14.9936 12.936C14.9936 11.2799 13.6512 9.9375 11.9951 9.9375C10.339 9.9375 8.99658 11.2799 8.99658 12.936C8.99658 14.5921 10.339 15.9345 11.9951 15.9345Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 13.8137V12.0546C2 11.0151 2.84958 10.1556 3.89905 10.1556C5.70815 10.1556 6.44778 8.87621 5.53823 7.307C5.01849 6.40745 5.32834 5.23803 6.23788 4.71829L7.96702 3.72879C8.75662 3.25902 9.77611 3.53888 10.2459 4.32849L10.3558 4.51839C11.2554 6.08761 12.7346 6.08761 13.6442 4.51839L13.7541 4.32849C14.2239 3.53888 15.2434 3.25902 16.033 3.72879L17.7621 4.71829C18.6717 5.23803 18.9815 6.40745 18.4618 7.307C17.5522 8.87621 18.2919 10.1556 20.1009 10.1556C21.1404 10.1556 22 11.0051 22 12.0546V13.8137C22 14.8532 21.1504 15.7128 20.1009 15.7128C18.2919 15.7128 17.5522 16.9921 18.4618 18.5613C18.9815 19.4709 18.6717 20.6303 17.7621 21.15L16.033 22.1395C15.2434 22.6093 14.2239 22.3294 13.7541 21.5398L13.6442 21.3499C12.7446 19.7807 11.2654 19.7807 10.3558 21.3499L10.2459 21.5398C9.77611 22.3294 8.75662 22.6093 7.96702 22.1395L6.23788 21.15C5.32834 20.6303 5.01849 19.4609 5.53823 18.5613C6.44778 16.9921 5.70815 15.7128 3.89905 15.7128C2.84958 15.7128 2 14.8532 2 13.8137Z"
        stroke="#013957"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 13.8137V12.0546C2 11.0151 2.84958 10.1556 3.89905 10.1556C5.70815 10.1556 6.44778 8.87621 5.53823 7.307C5.01849 6.40745 5.32834 5.23803 6.23788 4.71829L7.96702 3.72879C8.75662 3.25902 9.77611 3.53888 10.2459 4.32849L10.3558 4.51839C11.2554 6.08761 12.7346 6.08761 13.6442 4.51839L13.7541 4.32849C14.2239 3.53888 15.2434 3.25902 16.033 3.72879L17.7621 4.71829C18.6717 5.23803 18.9815 6.40745 18.4618 7.307C17.5522 8.87621 18.2919 10.1556 20.1009 10.1556C21.1404 10.1556 22 11.0051 22 12.0546V13.8137C22 14.8532 21.1504 15.7128 20.1009 15.7128C18.2919 15.7128 17.5522 16.9921 18.4618 18.5613C18.9815 19.4709 18.6717 20.6303 17.7621 21.15L16.033 22.1395C15.2434 22.6093 14.2239 22.3294 13.7541 21.5398L13.6442 21.3499C12.7446 19.7807 11.2654 19.7807 10.3558 21.3499L10.2459 21.5398C9.77611 22.3294 8.75662 22.6093 7.96702 22.1395L6.23788 21.15C5.32834 20.6303 5.01849 19.4609 5.53823 18.5613C6.44778 16.9921 5.70815 15.7128 3.89905 15.7128C2.84958 15.7128 2 14.8532 2 13.8137Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;
