import classNames from 'classnames';
import { bool, func, number, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
} from '../../../components';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import { accountTypes } from '../../../util/accountTypes';

import css from './TopbarDesktop.module.css';

import BusinessIcon from './icons/BusinessIcon';
import CheckIcon from './icons/CheckIcon';
import DashboardIcon from './icons/DashboardIcon';
import DropdownIcon from './icons/DropdownIcon';
import FolderIcon from './icons/FolderIcon';
import MessageIcon from './icons/MessageIcon';
import NotificationIcon from './icons/NotificationIcon';
import QuestionIcon from './icons/QuestionIcon';
import SearchIcon from './icons/SearchIcon';
import StatisticIcon from './icons/StatisticIcon';
import UserIcon from './icons/UserIcon';
import SignoutIcon from './icons/SignoutIcon';
import SettingsIcon from './icons/SettingsIcon';

import { isClient, isConsultant } from '../../../util/user';
import WalletIcon from './icons/WalletIcon';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    isDark,
    hideMenu,
    hideNavbarSecondaryRow,
    isLogin,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const isUserClient = isClient(currentUser);
  const isUserConsultant = isConsultant(currentUser);

  // const classes = classNames(rootClassName || (isDark ? css.rootDark : css.rootLight), className);
  const classes = classNames(
    rootClassName || (authenticatedOnClientSide ? css.rootLight : css.rootDark),
    className
  );
  const filterOwnerType =
    currentUser?.attributes?.profile?.publicData?.accountType === accountTypes.client
      ? accountTypes.consultant
      : accountTypes.client;

  const avatarName =
    currentUser?.attributes?.profile?.firstName + ' ' + currentUser?.attributes?.profile?.lastName;

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearch}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isContactDetailsPage =
      page === 'ContactDetailsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isContactDetailsPage ? css.currentPage : null;
  };

  const profileMenu = isUserClient ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {/* <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourGigsLink" />
          </NamedLink>
        </MenuItem> */}
        {/* <MenuItem key="NewListingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('NewListingPage'))}
            name="NewListingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.addGigLink" />
          </NamedLink>
        </MenuItem> */}
        {/* 
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: 'orders' }}
          >
            <span className={css.menuItemBorder} />
            {'Inbox'}
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="ProfilePage">
          <div className={css.profileMenuAvatarContainer}>
            <Avatar
              className={css.profileMenuAvatar}
              user={currentUser}
              renderSizes="(max-width: 767px) 96px, 240px"
            // disableProfileLink
            />
            <p className={css.profileMenuName}>{avatarName}</p>
          </div>
        </MenuItem>

        <MenuItem key="YourProfilePage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('YourProfilePage'))}
            name="YourProfilePage"
          >
            <UserIcon />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ContactDetailsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ContactDetailsPage'))}
            name="ContactDetailsPage"
          >
            <SettingsIcon />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <SignoutIcon />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>

        <MenuItem key="ProfilePage">
          <div className={css.profileMenuAvatarContainer}>
            <Avatar
              className={css.profileMenuAvatar}
              user={currentUser}
              renderSizes="(max-width: 767px) 96px, 240px"
            />
            <p className={css.profileMenuName}>{avatarName}</p>
          </div>
        </MenuItem>

        <MenuItem key="YourProfilePage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('YourProfilePage'))}
            name="YourProfilePage"
          >
            <UserIcon />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="StripePayoutPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('StripePayoutPage'))}
            name="StripePayoutPage"
          >
            <WalletIcon />
            <FormattedMessage id="TopbarDesktop.payoutDetails" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="ContactDetailsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ContactDetailsPage'))}
            name="ContactDetailsPage"
          >
            <SettingsIcon />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <SignoutIcon />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const notificationLink = (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <NotificationIcon />
        {notificationDot}
      </span>
    </NamedLink>
  );

  const messageLink = (
    <NamedLink name="MessengerPage">
      <span className={css.linkText}>
        <MessageIcon />
      </span>
    </NamedLink>
  );

  const whatIsLink = (
    <NamedLink
      name="LandingPage"
      className={currentPage === 'LandingPage' ? css.linkCurrent : css.link}
    >
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.whatIs" />
      </span>
    </NamedLink>
  );

  const clientsLink = (
    <NamedLink
      name="LandingClientsPage"
      className={currentPage === 'LandingClientsPage' ? css.linkCurrent : css.link}
    >
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.clients" />
      </span>
    </NamedLink>
  );

  const professionalsLink = (
    <NamedLink
      name="LandingProfessionalsPage"
      className={currentPage === 'LandingProfessionalsPage' ? css.linkCurrent : css.link}
    >
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.professionals" />
      </span>
    </NamedLink>
  );

  const blogLink = (
    // <NamedLink name="BlogPage" className={currentPage === 'BlogPage' ? css.linkCurrent : css.link}>
    //   <span className={css.linkText}>
    //     <FormattedMessage id="TopbarDesktop.blog" />
    //   </span>
    // </NamedLink>
    <a href='/blog.html'>
      <div className={css.link}>
        <span className={css.linkText}>
          <FormattedMessage id="TopbarDesktop.blog" />
        </span>
      </div>
    </a>
  );

  const joinLink = authenticatedOnClientSide ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.join" />
      </span>
    </NamedLink>
  );

  const signInLink = authenticatedOnClientSide ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.signIn" />
      </span>
    </NamedLink>
  );

  const dashboardLink = (
    <NamedLink
      name="DashboardPage"
      className={currentPage === 'DashboardPage' ? css.linkCurrent : css.darkLink}
    >
      <DashboardIcon />
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.dashboard" />
      </span>
    </NamedLink>
  );

  const findGigsLink = (
    <NamedLink
      name="SearchPage"
      to={{ search: `?pub_ownerType=${filterOwnerType}` }}
      className={currentPage === 'SearchPage' ? css.linkCurrent : css.darkLink}
    >
      <BusinessIcon />
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.findGigs" />
      </span>
    </NamedLink>
  );

  const MyGigsLink = () => {
    const [isHoveredDropdownIcon, setIsHoveredDropdownIcon] = useState(false);

    return (
      <div
        onMouseEnter={() => setIsHoveredDropdownIcon(true)}
        onMouseLeave={() => setIsHoveredDropdownIcon(false)}
      >
        <NamedLink
          name="ManageListingsPage"
          className={
            currentPage === 'ManageListingsPage'
              ? css.linkCurrent
              : isHoveredDropdownIcon
                ? css.linkCurrent
                : css.darkLink
          }
        >
          <FolderIcon />
          <span className={css.linkText}>
            <FormattedMessage id="TopbarDesktop.myGigs" />
          </span>
          <DropdownIcon isHoveredDropdownIcon={isHoveredDropdownIcon} currentPage={currentPage} />
        </NamedLink>

        {isHoveredDropdownIcon && (
          <div className={css.gigsDropdown}>
            <NamedLink name="NewListingPage" className={css.dropdownLink}>
              <FormattedMessage id="TopbarDesktop.createNewGig" />
            </NamedLink>
            <NamedLink name="ManageListingsPage" className={css.dropdownLink}>
              <FormattedMessage id="TopbarDesktop.manageGigs" />
            </NamedLink>
          </div>
        )}
      </div>
    );
  };
  const reportsLink = (
    <NamedLink name="SearchPage" className={css.darkLink}>
      <StatisticIcon />
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.reportsLink" />
      </span>
    </NamedLink>
  );

  const FindProfessionalsAndGigs = () => {
    const [isHoveredDropdownIcon, setIsHoveredDropdownIcon] = useState(false);

    return (
      <div
        onMouseEnter={() => setIsHoveredDropdownIcon(true)}
        onMouseLeave={() => setIsHoveredDropdownIcon(false)}
      >
        <NamedLink
          name="SearchPage"
          className={isHoveredDropdownIcon ? css.linkCurrent : css.darkLink}
        >
          <UserIcon />
          <span className={css.linkText}>
            <FormattedMessage id="TopbarDesktop.findProfessionalsAndGigs" />
          </span>
          <DropdownIcon isHoveredDropdownIcon={isHoveredDropdownIcon} />
        </NamedLink>

        {isHoveredDropdownIcon && (
          <div className={css.professionalsDropdown}>
            <NamedLink
              name="SearchPage"
              to={{ search: `?pub_ownerType=${accountTypes.consultant}` }}
              className={css.dropdownLink}
            >
              <FormattedMessage id="TopbarDesktop.findProfessionals" />
            </NamedLink>
            <NamedLink
              name="SearchPage"
              to={{ search: `?pub_ownerType=${accountTypes.client}` }}
              className={css.dropdownLink}
            >
              <FormattedMessage id="TopbarDesktop.findGigs" />
            </NamedLink>
          </div>
        )}
      </div>
    );
  };
  const helpAndSupport = (
    <NamedLink
      name="SearchPage"
      // to={{ search: `?pub_ownerType=${filterOwnerType}` }}
      className={css.darkLink}
    >
      <QuestionIcon />
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.helpAndSupport" />
      </span>
    </NamedLink>
  );

  return (
    <>
      {authenticatedOnClientSide && !hideMenu ? (
        <nav className={classes}>
          <div className={css.navbarRowLight}>
            <LinkedLogo
              className={css.logoLink}
              isDark={isDark}
              format="desktop"
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
            />
            {search}
            <div className={css.rightSideNavbar}>
              <div className={css.profileContainer}>
                {profileMenu}
                <div>
                  <p className={css.avatarName}>{avatarName}</p>
                  <p className={css.accountType}>
                    {isUserConsultant ? (
                      <FormattedMessage id="Topbar.accountConsultant" />
                    ) : isUserClient ? (
                      <FormattedMessage id="Topbar.accountClient" />
                    ) : (
                      <FormattedMessage id="Topbar.accountNotDefined" />
                    )}
                  </p>
                </div>
              </div>
              {notificationLink}
              {messageLink}
            </div>
          </div>

          {isUserConsultant && !hideNavbarSecondaryRow ? (
            <div className={css.navbarRowLight}>
              <div className={css.displayFlex}>
                {dashboardLink}
                {findGigsLink}
                <MyGigsLink />
                {reportsLink}
              </div>
              {helpAndSupport}
            </div>
          ) : isUserClient && !hideNavbarSecondaryRow ? (
            <div className={css.navbarRowLight}>
              <div className={css.displayFlex}>
                {dashboardLink}
                <FindProfessionalsAndGigs />
                <MyGigsLink />
                {reportsLink}
              </div>
              {helpAndSupport}
            </div>
          ) : null}
        </nav>
      ) : !hideMenu ? (
        <nav className={classes}>
          <div className={css.navbarRowDark}>
            <LinkedLogo
              className={css.logoLink}
              isDark={!isDark}
              format="desktop"
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
            />
            {whatIsLink}
            {clientsLink}
            {professionalsLink}
            {blogLink}
            {(signInLink || joinLink) && (
              <div className={css.buttonsWrapper}>
                {signInLink}
                {joinLink}
              </div>
            )}
          </div>
        </nav>
      ) : (
        <nav className={classes}>
          <div className={css.navbarRowDark}>
            <LinkedLogo
              className={css.logoLink}
              isDark={!isDark}
              format="desktop"
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
            />
            {/* <div className={css.hideMenuRightSideNavbar}>
              {blogLink}
              <div className={css.buttonsWrapper}>{isLogin ? joinLink : signInLink}</div>
            </div> */}
          </div>
        </nav>
      )}

      {/* {inboxLink} */}

      {/* <NamedLink className={css.createListingLink} name="NewListingPage">
                <span className={css.createListing}>
                  <FormattedMessage id="TopbarDesktop.createListing" />
                </span>
          </NamedLink> */}
    </>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
