import React from 'react';

const SearchFiltersIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0151 19.0678C14.0151 19.6781 13.6148 20.4784 13.1046 20.7886L11.694 21.699C10.3834 22.5094 8.56254 21.5989 8.56254 19.9782V14.6258C8.56254 13.9154 8.16236 13.005 7.75217 12.5048L3.91039 8.46294C3.40015 7.95271 3 7.05231 3 6.44203V4.12096C3 2.91041 3.91044 2 5.02094 2H18.367C19.4775 2 20.388 2.91041 20.388 4.02091V6.24193C20.388 7.0523 19.8778 8.06277 19.3775 8.563"
        stroke="#013957"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0151 19.0678C14.0151 19.6781 13.6148 20.4784 13.1046 20.7886L11.694 21.699C10.3834 22.5094 8.56254 21.5989 8.56254 19.9782V14.6258C8.56254 13.9154 8.16236 13.005 7.75217 12.5048L3.91039 8.46294C3.40015 7.95271 3 7.05231 3 6.44203V4.12096C3 2.91041 3.91044 2 5.02094 2H18.367C19.4775 2 20.388 2.91041 20.388 4.02091V6.24193C20.388 7.0523 19.8778 8.06277 19.3775 8.563"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7657 16.5162C17.5338 16.5162 18.9671 15.0829 18.9671 13.3147C18.9671 11.5466 17.5338 10.1133 15.7657 10.1133C13.9976 10.1133 12.5642 11.5466 12.5642 13.3147C12.5642 15.0829 13.9976 16.5162 15.7657 16.5162Z"
        stroke="#013957"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7657 16.5162C17.5338 16.5162 18.9671 15.0829 18.9671 13.3147C18.9671 11.5466 17.5338 10.1133 15.7657 10.1133C13.9976 10.1133 12.5642 11.5466 12.5642 13.3147C12.5642 15.0829 13.9976 16.5162 15.7657 16.5162Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5676 17.1176L18.5671 16.1172"
        stroke="#013957"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5676 17.1176L18.5671 16.1172"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchFiltersIcon;
