import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const QuestionIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 14.1311C11.4896 14.1311 11.0664 13.7079 11.0664 13.1975V12.9361C11.0664 11.4921 12.1245 10.7825 12.5228 10.5087C12.9834 10.1974 13.1328 9.98584 13.1328 9.66218C13.1328 9.03977 12.6224 8.52934 12 8.52934C11.3776 8.52934 10.8672 9.03977 10.8672 9.66218C10.8672 10.1726 10.4439 10.5958 9.93357 10.5958C9.42324 10.5958 9 10.1726 9 9.66218C9 8.00657 10.3444 6.66211 12 6.66211C13.6556 6.66211 15 8.00657 15 9.66218C15 11.0813 13.9543 11.7908 13.5685 12.0522C13.083 12.3759 12.9336 12.5875 12.9336 12.9361V13.1975C12.9336 13.7203 12.5104 14.1311 12 14.1311Z"
        fill="#012E46"
      />
      <path
        d="M12 17.2305C11.4772 17.2305 11.0664 16.8073 11.0664 16.2969C11.0664 15.7865 11.4896 15.3633 12 15.3633C12.5104 15.3633 12.9336 15.7865 12.9336 16.2969C12.9336 16.8073 12.5228 17.2305 12 17.2305Z"
        fill="#012E46"
      />
      <path
        d="M12 22C6.48372 22 2 17.5163 2 12C2 6.48372 6.48372 2 12 2C17.5163 2 22 6.48372 22 12C22 17.5163 17.5163 22 12 22ZM12 3.39535C7.25581 3.39535 3.39535 7.25581 3.39535 12C3.39535 16.7442 7.25581 20.6046 12 20.6046C16.7442 20.6046 20.6046 16.7442 20.6046 12C20.6046 7.25581 16.7442 3.39535 12 3.39535Z"
        fill="#012E46"
      />
    </svg>
  );
};

export default QuestionIcon;
