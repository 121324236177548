import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const NotificationIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0265 19.8925C9.63469 19.8925 7.24291 19.5504 4.97431 18.8661C4.11203 18.598 3.45506 18.0524 3.16764 17.3405C2.86995 16.6285 2.9726 15.8425 3.4448 15.1398L4.62529 13.3737C4.87165 13.0039 5.09749 12.2642 5.09749 11.8296V9.15737C5.09749 5.71769 8.20783 2.91602 12.0265 2.91602C15.8451 2.91602 18.9555 5.71769 18.9555 9.15737V11.8296C18.9555 12.2549 19.1813 13.0039 19.4277 13.383L20.5979 15.1398C21.0393 15.8056 21.1214 16.61 20.8238 17.3405C20.5261 18.0709 19.8794 18.6257 19.0684 18.8661C16.8101 19.5504 14.4183 19.8925 12.0265 19.8925ZM12.0265 4.30298C9.05984 4.30298 6.63726 6.4759 6.63726 9.15737V11.8296C6.63726 12.5046 6.32931 13.5032 5.9495 14.0857L4.769 15.8518C4.54317 16.1939 4.48158 16.5545 4.61503 16.8596C4.73821 17.174 5.04616 17.4144 5.46703 17.5439C9.75787 18.8384 14.3054 18.8384 18.5962 17.5439C18.9658 17.4329 19.2532 17.1833 19.3866 16.8504C19.5201 16.5175 19.4893 16.1569 19.284 15.8518L18.1035 14.0857C17.7134 13.4847 17.4157 12.4953 17.4157 11.8203V9.15737C17.4157 6.4759 15.0034 4.30298 12.0265 4.30298Z"
        fill="#012E46"
      />
      <path
        d="M13.9357 4.55202C13.8639 4.55202 13.792 4.54277 13.7201 4.52428C13.4225 4.45031 13.135 4.39483 12.8579 4.35785C11.9853 4.25613 11.1436 4.31161 10.3532 4.52428C10.0657 4.6075 9.75782 4.52428 9.56278 4.33011C9.36774 4.13593 9.30615 3.85854 9.41907 3.60888C9.83994 2.63801 10.8664 2 12.0367 2C13.2069 2 14.2334 2.62876 14.6543 3.60888C14.7569 3.85854 14.7056 4.13593 14.5106 4.33011C14.3566 4.47805 14.141 4.55202 13.9357 4.55202Z"
        fill="#012E46"
      />
      <path
        d="M12.0262 22.0006C11.0099 22.0006 10.0245 21.6308 9.30593 20.9835C8.58736 20.3363 8.17676 19.4486 8.17676 18.5332H9.71653C9.71653 19.0787 9.96287 19.615 10.394 20.0034C10.8251 20.3917 11.4205 20.6137 12.0262 20.6137C13.299 20.6137 14.3358 19.6798 14.3358 18.5332H15.8756C15.8756 20.4472 14.1511 22.0006 12.0262 22.0006Z"
        fill="#012E46"
      />
    </svg>
  );
};

export default NotificationIcon;
