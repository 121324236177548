import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { scrollToTop } from '../../util/scrollToTop';

import css from './TabNav.module.css';
import SquarePillIcon from './icons/SquarePillIcon'

const Tab = props => {
  const { className, id, disabled, text, selected, isGeneralSettings, setCurrentStep, step, linkProps } = props;
  const [isHovered, setIsHovered] = useState(false);

  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected || isHovered,
    [css.disabled]: disabled,
  });

  return isGeneralSettings ?
    <div className={linkClasses}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        setCurrentStep(step);
        scrollToTop();
      }}
    >
      <span id={id} className={className}>
        {text}
        <SquarePillIcon selected={selected} hovered={isHovered} />
      </span>
    </div > :
    <div
      // className={linkClasses}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <NamedLink id={id} className={linkClasses} {...linkProps}>
        {text}
        <SquarePillIcon selected={selected} hovered={isHovered} />
      </NamedLink>
    </div>
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const { className, rootClassName, tabRootClassName, tabs } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return <Tab key={id} id={id} className={tabClasses} {...tab} />;
      })}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
