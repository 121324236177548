import React from 'react';

const DotsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.16667 10.3333C5.25 10.3333 4.5 11.0833 4.5 11.9999C4.5 12.9166 5.25 13.6666 6.16667 13.6666C7.08333 13.6666 7.83333 12.9166 7.83333 11.9999C7.83333 11.0833 7.08333 10.3333 6.16667 10.3333Z"
        stroke="#013957"
        strokeWidth="1.25"
      />
      <path
        d="M6.16667 10.3333C5.25 10.3333 4.5 11.0833 4.5 11.9999C4.5 12.9166 5.25 13.6666 6.16667 13.6666C7.08333 13.6666 7.83333 12.9166 7.83333 11.9999C7.83333 11.0833 7.08333 10.3333 6.16667 10.3333Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.25"
      />
      <path
        d="M17.8337 10.3333C16.917 10.3333 16.167 11.0833 16.167 11.9999C16.167 12.9166 16.917 13.6666 17.8337 13.6666C18.7503 13.6666 19.5003 12.9166 19.5003 11.9999C19.5003 11.0833 18.7503 10.3333 17.8337 10.3333Z"
        stroke="#013957"
        strokeWidth="1.25"
      />
      <path
        d="M17.8337 10.3333C16.917 10.3333 16.167 11.0833 16.167 11.9999C16.167 12.9166 16.917 13.6666 17.8337 13.6666C18.7503 13.6666 19.5003 12.9166 19.5003 11.9999C19.5003 11.0833 18.7503 10.3333 17.8337 10.3333Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.25"
      />
      <path
        d="M11.9997 10.3333C11.083 10.3333 10.333 11.0833 10.333 11.9999C10.333 12.9166 11.083 13.6666 11.9997 13.6666C12.9163 13.6666 13.6663 12.9166 13.6663 11.9999C13.6663 11.0833 12.9163 10.3333 11.9997 10.3333Z"
        stroke="#013957"
        strokeWidth="1.25"
      />
      <path
        d="M11.9997 10.3333C11.083 10.3333 10.333 11.0833 10.333 11.9999C10.333 12.9166 11.083 13.6666 11.9997 13.6666C12.9163 13.6666 13.6663 12.9166 13.6663 11.9999C13.6663 11.0833 12.9163 10.3333 11.9997 10.3333Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.25"
      />
    </svg>
  );
};

export default DotsIcon;
