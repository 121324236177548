import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const DashboardIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.386 21.9782H6.61395C4.06512 21.9782 2 19.9038 2 17.3549V10.4619C2 9.19677 2.7814 7.60608 3.78605 6.82468L8.8 2.91771C10.307 1.74562 12.7163 1.6898 14.2791 2.78747L20.0279 6.81538C21.1349 7.58747 22 9.24329 22 10.5922V17.3642C22 19.9038 19.9349 21.9782 17.386 21.9782ZM9.65581 4.01538L4.64186 7.92236C3.9814 8.44329 3.39535 9.62468 3.39535 10.4619V17.3549C3.39535 19.1317 4.83721 20.5828 6.61395 20.5828H17.386C19.1628 20.5828 20.6047 19.141 20.6047 17.3642V10.5922C20.6047 9.6991 19.9628 8.46189 19.2279 7.95957L13.4791 3.93166C12.4186 3.18747 10.6698 3.22468 9.65581 4.01538Z"
        fill="#012E46"
      />
      <path
        d="M11.9999 18.2564C11.6185 18.2564 11.3022 17.9401 11.3022 17.5587V14.768C11.3022 14.3866 11.6185 14.0703 11.9999 14.0703C12.3813 14.0703 12.6976 14.3866 12.6976 14.768V17.5587C12.6976 17.9401 12.3813 18.2564 11.9999 18.2564Z"
        fill="#012E46"
      />
    </svg>
  );
};

export default DashboardIcon;
