import React from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './SelectorTags.module.css';
import { selectors } from './selectors';
import AddIcon from './AddIcon';

const SelectorTagsComponent = props => {
  const { onSelectorClick, activeSelector, isSearchPage, isManageListingsPage, isSettingsPage } = props;

  return (
    <>
      {isManageListingsPage && (
        <div className={css.root}>
          <div className={css.container}>
            <span
              className={activeSelector === selectors.all ? css.activeSelector : css.selector}
              onClick={() => onSelectorClick(selectors.all)}
            >
              <FormattedMessage id={`SelectorTags.${selectors.all}`} />
            </span>
            <span
              className={activeSelector === selectors.yourGigs ? css.activeSelector : css.selector}
              onClick={() => onSelectorClick(selectors.yourGigs)}
            >
              <FormattedMessage id={`SelectorTags.${selectors.yourGigs}`} />
            </span>
            <span
              className={activeSelector === selectors.purchasedGigs ? css.activeSelector : css.selector}
              onClick={() => onSelectorClick(selectors.purchasedGigs)}
            >
              <FormattedMessage id={`SelectorTags.${selectors.purchasedGigs}`} />
            </span>
          </div>
          <div className={css.selectorContainer}>
            <NamedLink name="NewListingPage" className={css.selector}>
              <AddIcon />
              <FormattedMessage id="SelectorTags.createNewGig" />
            </NamedLink>
          </div>
        </div>
      )}

      {isSearchPage && (
        <div className={css.root}>
          <div className={css.container}>
            <span
              className={activeSelector === selectors.all ? css.activeSelector : css.selector}
              onClick={() => onSelectorClick(selectors.all)}
            >
              <FormattedMessage id={`SelectorTags.${selectors.all}`} />
            </span>
            <span
              className={activeSelector === selectors.favorites ? css.activeSelector : css.selector}
              onClick={() => onSelectorClick(selectors.favorites)}
            >
              <FormattedMessage id={`SelectorTags.${selectors.favorites}`} />
            </span>
            <span
              className={activeSelector === selectors.recentlyViewed ? css.activeSelector : css.selector}
              onClick={() => onSelectorClick(selectors.recentlyViewed)}
            >
              <FormattedMessage id={`SelectorTags.${selectors.recentlyViewed}`} />
            </span>
            <span
              className={activeSelector === selectors.bestMatched ? css.activeSelector : css.selector}
              onClick={() => onSelectorClick(selectors.bestMatched)}
            >
              <FormattedMessage id={`SelectorTags.${selectors.bestMatched}`} />
            </span>
            <span
              className={activeSelector === selectors.topRated ? css.activeSelector : css.selector}
              onClick={() => onSelectorClick(selectors.topRated)}
            >
              <FormattedMessage id={`SelectorTags.${selectors.topRated}`} />
            </span>
            <span
              className={activeSelector === selectors.availableNow ? css.activeSelector : css.selector}
              onClick={() => onSelectorClick(selectors.availableNow)}
            >
              <FormattedMessage id={`SelectorTags.${selectors.availableNow}`} />
            </span>
          </div>
        </div>
      )}

      {isSettingsPage && (
        <div className={css.root}>
          <div className={css.container}>
            <span
              className={activeSelector === selectors.personalInformation ? css.activeSelector : css.selector}
              onClick={() => onSelectorClick(selectors.personalInformation)}
            >
              <FormattedMessage id={`SelectorTags.${selectors.personalInformation}`} />
            </span>

            <span
              className={activeSelector === selectors.notifications ? css.activeSelector : css.selector}
              onClick={() => onSelectorClick(selectors.notifications)}
            >
              <FormattedMessage id={`SelectorTags.${selectors.notifications}`} />

            </span>
          </div>
        </div>
      )}
    </>
  )
};

const SelectorTags = compose(injectIntl)(SelectorTagsComponent);
SelectorTags.displayName = 'SelectorTags';

export default SelectorTags;
