import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const MessageIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.27907 19.1907C6.0186 19.1907 5.74882 19.1255 5.50696 18.9953C4.97673 18.7162 4.65116 18.1581 4.65116 17.5628V16.2419C1.84186 15.9535 0 13.8883 0 10.9302V5.34884C0 2.14884 2.14884 0 5.34884 0H14.6512C17.8512 0 20 2.14884 20 5.34884V10.9302C20 14.1302 17.8512 16.279 14.6512 16.279H11.1442L7.18137 18.921C6.9116 19.0977 6.59535 19.1907 6.27907 19.1907ZM5.34884 1.38604C2.94884 1.38604 1.39535 2.93953 1.39535 5.33953V10.921C1.39535 13.321 2.94884 14.8745 5.34884 14.8745C5.73023 14.8745 6.04651 15.1908 6.04651 15.5722V17.5535C6.04651 17.6745 6.12093 17.7303 6.16744 17.7582C6.21396 17.7861 6.30699 17.814 6.40931 17.7489L10.5488 14.9954C10.6605 14.921 10.8 14.8745 10.9395 14.8745H14.6605C17.0605 14.8745 18.614 13.321 18.614 10.921V5.33953C18.614 2.93953 17.0605 1.38604 14.6605 1.38604H5.34884Z"
        fill="#012E46"
      />
    </svg>
  );
};

export default MessageIcon;
