export const timeDiffAsString = (date, intl) => {
  const now = new Date();
  const diff = now - date;

  const diffInMinutes = Math.round(diff / 60000);
  if (diffInMinutes < 60) {
    return intl.formatMessage({ id: 'TimeDiff.minutesAgo' }, { diff: diffInMinutes });
  }

  const diffInHours = Math.round(diffInMinutes / 60);
  if (diffInHours < 24) {
    return intl.formatMessage({ id: 'TimeDiff.hoursAgo' }, { diff: diffInHours });
  }

  const diffInDays = Math.round(diffInHours / 24);
  return intl.formatMessage({ id: 'TimeDiff.daysAgo' }, { diff: diffInDays });
};

