import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import ValidationError from '../ValidationError/ValidationError';
import css from './FieldCustomSelect.module.css';
import arrow from './images/arrow.png';

const FieldCustomSelect = props => {
    const { name, id, label, placeholder, options, customErrorText, meta, disabled } = props;


    const FieldCustomSelect = ({ input: { onChange, onFocus, onBlur, value }, label, placeholder, customErrorText, meta, ...rest }) => {
        const wrapperRef = useRef(null);
        const [showOptions, setShowOptions] = useState(false);
        const [selectedOption, setSelectedOption] = useState(value || null);

        const { valid, invalid, error, touched } = meta;
        const errorText = customErrorText || error;
        const hasError = !!customErrorText || !!(touched && invalid && error);

        const fieldMeta = { touched: hasError, error: errorText };

        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setShowOptions(false);
                }
            }
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [wrapperRef]);

        const selectedOptionLabel =
            selectedOption ? options.find(o => o.key === selectedOption)?.label : placeholder;
        return (
            <div className={css.wrapper}>
                <label className={css.label}>{label}</label>
                <div
                    className={css.selectInput}
                    onClick={() => {
                        if (disabled) return;
                        setShowOptions(!showOptions)
                    }
                    }
                    ref={wrapperRef}
                    onMouseEnter={() => wrapperRef.current.focus()}
                    tabIndex="0"
                    onFocus={(event) => onFocus(event)} onBlur={(event) => onBlur(event)}

                >
                    <div className={css.selectedOption}>{selectedOptionLabel}</div>
                    <img src={arrow} className={showOptions ? css.arrowRotated : css.arrow} />
                    {showOptions && (
                        <div className={css.options}>
                            {options.map((o, index) => {
                                return (
                                    <div key={o.key}
                                        className={css.option}
                                        onClick={() => {
                                            onChange(o.key);
                                            setSelectedOption(o.key);
                                        }}
                                    >
                                        {o.label}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                <ValidationError fieldMeta={fieldMeta} />
            </div>
        );
    };

    return <Field name={name} label={label} placeholder={placeholder} id={id} component={FieldCustomSelect} {...props} />;
};

export default FieldCustomSelect;
