import React from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import css from "./AsideCardBlured.module.css"

const AsideCardBluredComponent = (props) => {
    const { children } = props;
    return (
        <div className={css.root}>
            {children}
        </div>
    );
}

const AsideCardBlured = compose(injectIntl)(AsideCardBluredComponent);
AsideCardBlured.displayName = 'AsideCardBlured';

export default AsideCardBlured;