import React from 'react';

const LocationIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      style={{ fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7234 14.5125C9.74285 14.5125 8.125 12.904 8.125 10.9142C8.125 8.92445 9.74285 7.3252 11.7234 7.3252C13.7038 7.3252 15.3217 8.93375 15.3217 10.9235C15.3217 12.9133 13.7038 14.5125 11.7234 14.5125ZM11.7234 8.7199C10.5146 8.7199 9.5197 9.70548 9.5197 10.9235C9.5197 12.1415 10.5053 13.1271 11.7234 13.1271C12.9414 13.1271 13.927 12.1415 13.927 10.9235C13.927 9.70548 12.9321 8.7199 11.7234 8.7199Z"
        fill="#013957"
      />
      <path
        d="M11.7184 22.5C10.3423 22.5 8.9569 21.9793 7.87833 20.9472C5.13542 18.3066 2.10427 14.0946 3.24792 9.08298C4.28 4.53626 8.25025 2.5 11.7184 2.5H11.7277C15.1959 2.5 19.1661 4.53626 20.1982 9.09228C21.3325 14.1039 18.3014 18.3066 15.5585 20.9472C14.4799 21.9793 13.0945 22.5 11.7184 22.5ZM11.7184 3.8947C9.01268 3.8947 5.53523 5.33589 4.61473 9.38982C3.61055 13.7692 6.36275 17.5442 8.85462 19.9338C10.4632 21.4865 12.9829 21.4865 14.5915 19.9338C17.0741 17.5442 19.8263 13.7692 18.8407 9.38982C17.9109 5.33589 14.4241 3.8947 11.7184 3.8947Z"
        fill="#013957"
      />
    </svg>
  );
};

export default LocationIcon;
