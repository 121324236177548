import React from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import css from "./AsideCardWhite.module.css"

const AsideCardWhiteComponent = (props) => {
    const { children } = props;
    return (
        <div className={css.root}>
            {children}
        </div>
    );
}

const AsideCardWhite = compose(injectIntl)(AsideCardWhiteComponent);
AsideCardWhite.displayName = 'AsideCardWhite';

export default AsideCardWhite;