import React from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import closeIcon from './images/close_icon.png';
import css from './Banner.module.css';

const BannerComponent = props => {
  const { handleCloseBanner } = props;
  return (
    <div className={css.root}>
      <div className={css.content}>
        <h1 className={css.title}>
          <FormattedMessage id={'BannerSearchPage.title1'} />
        </h1>
        <h1 className={css.title}>
          <FormattedMessage id={'BannerSearchPage.title2'} />
        </h1>
        <h1 className={css.title}>
          <FormattedMessage id={'BannerSearchPage.title3'} />
        </h1>

        <NamedLink name="LoginPage" className={css.buttonLink}>
          <FormattedMessage id={'BannerSearchPage.button'} />
        </NamedLink>
      </div>
      <img className={css.closeIcon} src={closeIcon} onClick={handleCloseBanner} />
    </div>
  );
};

const Banner = compose(injectIntl)(BannerComponent);
Banner.displayName = 'Banner';

export default Banner;
