import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

import css from './TopbarSearchForm.module.css';

const SearchIcon = () => {
  return (
    <svg
      className={css.iconSvg}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1042 21.0716C6.84785 21.0716 2.56836 16.7921 2.56836 11.5358C2.56836 6.27949 6.84785 2 12.1042 2C17.3605 2 21.64 6.27949 21.64 11.5358C21.64 16.7921 17.3605 21.0716 12.1042 21.0716ZM12.1042 3.39549C7.61071 3.39549 3.96384 7.05166 3.96384 11.5358C3.96384 16.02 7.61071 19.6761 12.1042 19.6761C16.5976 19.6761 20.2445 16.02 20.2445 11.5358C20.2445 7.05166 16.5976 3.39549 12.1042 3.39549Z"
        fill="#012E46"
      />
      <path
        d="M21.8727 22.0016C21.696 22.0016 21.5192 21.9364 21.3797 21.7969L19.5192 19.9364C19.2495 19.6667 19.2495 19.2201 19.5192 18.9504C19.789 18.6806 20.2355 18.6806 20.5053 18.9504L22.3657 20.8108C22.6355 21.0806 22.6355 21.5271 22.3657 21.7969C22.2262 21.9364 22.0495 22.0016 21.8727 22.0016Z"
        fill="#012E46"
      />
    </svg>
  );
};

export default SearchIcon;
