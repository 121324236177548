import React from 'react';

const SquarePillIcon = (props) => {
  const { selected, hovered } = props;
  const fillColor = (selected || hovered) ? "#cc9b0a" : "#E2E8F0";
  const fillOpacity = (selected || hovered) ? "1" : "0.8";

  return (
    <svg width="36" height="32" viewBox="0 0 36 32" style={{ fill: "none" }} xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="32" rx="10" fill={fillColor} fillOpacity={fillOpacity} />
      <path d="M15 22L21 16L15 10" stroke="#013957" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 22L21 16L15 10" stroke="black" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SquarePillIcon;
