import React from 'react';

const VerifiedIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ fill: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.375 11.9885L10.7841 14.4076L15.6123 9.56934"
        stroke="#012E46"
        strokeWidth="1.44444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7417 2.44233C11.4315 1.85256 12.561 1.85256 13.2608 2.44233L14.8402 3.80182C15.1401 4.06173 15.6999 4.27165 16.0997 4.27165H17.7991C18.8587 4.27165 19.7284 5.14132 19.7284 6.20092V7.90029C19.7284 8.29014 19.9383 8.85993 20.1982 9.15981L21.5577 10.7392C22.1474 11.4289 22.1474 12.5585 21.5577 13.2582L20.1982 14.8376C19.9383 15.1375 19.7284 15.6973 19.7284 16.0972V17.7965C19.7284 18.8561 18.8587 19.7258 17.7991 19.7258H16.0997C15.7099 19.7258 15.1401 19.9357 14.8402 20.1956L13.2608 21.5551C12.571 22.1449 11.4415 22.1449 10.7417 21.5551L9.16231 20.1956C8.86243 19.9357 8.30264 19.7258 7.90279 19.7258H6.17343C5.11383 19.7258 4.24416 18.8561 4.24416 17.7965V16.0872C4.24416 15.6973 4.03424 15.1375 3.78433 14.8376L2.43484 13.2482C1.85505 12.5585 1.85505 11.4389 2.43484 10.7492L3.78433 9.15981C4.03424 8.85993 4.24416 8.30014 4.24416 7.91028V6.19093C4.24416 5.13133 5.11383 4.26165 6.17343 4.26165H7.90279C8.29264 4.26165 8.86243 4.05173 9.16231 3.79183L10.7417 2.44233Z"
        stroke="#012E46"
        strokeWidth="1.44444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VerifiedIcon;
