import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';

const FolderIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1473 21.5771H7.84493C3.74261 21.5771 2.49609 20.3306 2.49609 16.2283V6.92599C2.49609 2.82366 3.74261 1.57715 7.84493 1.57715H9.24028C10.8682 1.57715 11.3798 2.10738 12.031 2.9725L13.4263 4.83296C13.7333 5.24226 13.7798 5.29808 14.3566 5.29808H17.1473C21.2496 5.29808 22.4961 6.54459 22.4961 10.6469V16.2283C22.4961 20.3306 21.2496 21.5771 17.1473 21.5771ZM7.84493 2.9725C4.524 2.9725 3.89144 3.60506 3.89144 6.92599V16.2283C3.89144 19.5492 4.524 20.1818 7.84493 20.1818H17.1473C20.4682 20.1818 21.1007 19.5492 21.1007 16.2283V10.6469C21.1007 7.32599 20.4682 6.69343 17.1473 6.69343H14.3566C13.1659 6.69343 12.7752 6.28413 12.31 5.67017L10.9147 3.80971C10.431 3.16785 10.2914 2.9725 9.24028 2.9725H7.84493Z"
        fill="#013957"
      />
      <path
        d="M17.1473 21.5771H7.84493C3.74261 21.5771 2.49609 20.3306 2.49609 16.2283V6.92599C2.49609 2.82366 3.74261 1.57715 7.84493 1.57715H9.24028C10.8682 1.57715 11.3798 2.10738 12.031 2.9725L13.4263 4.83296C13.7333 5.24226 13.7798 5.29808 14.3566 5.29808H17.1473C21.2496 5.29808 22.4961 6.54459 22.4961 10.6469V16.2283C22.4961 20.3306 21.2496 21.5771 17.1473 21.5771ZM7.84493 2.9725C4.524 2.9725 3.89144 3.60506 3.89144 6.92599V16.2283C3.89144 19.5492 4.524 20.1818 7.84493 20.1818H17.1473C20.4682 20.1818 21.1007 19.5492 21.1007 16.2283V10.6469C21.1007 7.32599 20.4682 6.69343 17.1473 6.69343H14.3566C13.1659 6.69343 12.7752 6.28413 12.31 5.67017L10.9147 3.80971C10.431 3.16785 10.2914 2.9725 9.24028 2.9725H7.84493Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export default FolderIcon;
